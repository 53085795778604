'use strict';

/**
* Injects the Leadanizer script into the current webpage.
* This function creates a new script element with the source set to the Leadanizer
* configuration URL and appends it to the document's head. The script is loaded
* asynchronously to avoid blocking the page rendering.
*
* The script is identified by the ID "leadanizer" and is associated with the
* Leadanizer ID "schaffrath". This setup is used for integrating the Leadanizer
* funnel configuration into the webpage.
*/
function injectLeadanizerScript() {
    window.__be = window.__be || {};
    const leadanizerID = "schaffrath";

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://www.ldnzr.de/schaffrath/funnelconfig.js';
    script.setAttribute('id', 'leadanizer');

    const scriptElement = document.querySelector('script[src$="leadanizer.js"]');
    const isPageWhiteListed = scriptElement.getAttribute('data-is-page-whitelisted');

    if (isPageWhiteListed && isPageWhiteListed !== 'false') {
        const htmlElement = document.documentElement;
        htmlElement.classList.add('leadanizer-on');
    }

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);

    const div = document.createElement('div');
    div.id = 'appointmentData';
    div.style.display = 'none';
    div.textContent = 'appointmentData:vk=alle&doi=no';
    document.body.appendChild(div);
}

document.addEventListener('DOMContentLoaded', function () {
    injectLeadanizerScript();
});
